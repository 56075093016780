









































import Vue from 'vue'
import ShellMixin from '~/mixins/shellMixin'
import Picture from '~/components/commons/bases/picture/picture.vue'
export default Vue.extend({
  name: 'QuadItemsLayout',
  components: {
    Picture
  },
  mixins: [ShellMixin],
  props: {
    /**
    enum ['grey', 'white']
     */
    backgroundColor: {
      type: String,
      required: false,
      default: 'white',
      validator: color =>
        [
          'grey',
          'white',
          'yellow',
          'gradient-grey',
          'productCar',
          'productBB',
          'productNMC'
        ].includes(color)
    },
    backgroundImage: {
      type: String,
      required: false,
      default: undefined
    },
    imgDesktop: {
      type: String,
      required: true,
      default: ''
    },
    imgTablet: {
      type: String,
      required: true,
      default: ''
    },
    imgMobile: {
      type: String,
      required: true,
      default: ''
    },
    altText: {
      type: String,
      required: false,
      default: ''
    },
    imgId: {
      type: String,
      required: false,
      default: 'imgId'
    },
    testId: {
      type: String,
      required: false,
      default: 'carouselImage'
    }
  }
  // mounted() {
  //   this.loading = true;
  //   console.log('ssssss')
  //   console.log(this.loading)
  // }
})

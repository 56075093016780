
















































































import Vue from 'vue'
import BadgeBox from '~/components/commons/organisms/badge-box/badge-box.vue'
import QuadItemsLayout from '~/components/layouts/quad-items-layout/quad-items-layout.vue'
import WithAnimation from '~/components/commons/enhancers/with-animation.vue'
import DividerLine from '~/components/commons/bases/divider-line/divider-line.vue'
import { TextBody } from '~/components/commons/bases/typography'
import ShellMixin from '~/mixins/shellMixin'

export default Vue.extend({
  components: {
    BadgeBox,
    QuadItemsLayout,
    WithAnimation,
    DividerLine,
    TextBody
  },
  mixins: [ShellMixin]
})

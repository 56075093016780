
















import Vue from 'vue'
import { TitleMedium } from '~/components/commons/bases/typography'

export default Vue.extend({
  name: 'QuadItemsLayout',
  components: {
    TitleMedium
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    /**
    enum ['grey', 'white']
     */
    backgroundColor: {
      type: String,
      required: false,
      default: 'white',
      validator: color => ['grey', 'white'].includes(color)
    },
    /**
     * enum ['tablet-cols-2', 'tablet-cols-4']
     */
    model: {
      type: String,
      required: false,
      default: 'tablet-cols-2',
      validator: type => ['tablet-cols-2', 'tablet-cols-4'].includes(type)
    }
  }
})

<template>
  <div
    class="blog-contanier"
    :class="{ 'revamp-blogs': isShowRevamp(), 'is-homepage': homepage }"
    :style="{ 'background-color': background }"
  >
    <div class="blogs">
      <div class="wrap-title">
        <TitleMedium id="topic-footer-content" color="outer-space" as="h2">
          บทความน่าสนใจ
        </TitleMedium>
        <nuxt-link
          data-test="contents-all"
          class="read-all"
          to="/contents?events=2"
        >
          อ่านทั้งหมด
        </nuxt-link>
      </div>
      <div class="blog-section-list">
        <Card
          v-for="item in items"
          :key="item.dataTest"
          class="contents-body-item"
          :image="item.img.thumbNailPath"
          :alt-text="item.img.altText"
          :title="item.title"
          :description="item.seo.description"
          :link="item.path + '?events=' + events"
          :data-test="item.dataTest"
          target="_self"
          :background="background"
        ></Card>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { orderBy, filter } from 'lodash'
import { isAfter } from 'date-fns'
import Contents from '../contents/_categories/contents'
import { TitleMedium } from '~/components/commons/bases/typography'
import Card from '~/components/commons/bases/card/card.vue'

export default Vue.extend({
  components: {
    TitleMedium,
    Card
  },
  props: {
    homepage: {
      type: Boolean,
      default: false
    },
    events: {
      type: String,
      default: ''
    }
  },
  data() {
    const background = this.homepage ? '#ffffff' : '#f5f5f5'
    return {
      background,
      contents: Contents
    }
  },
  computed: {
    items() {
      const temp = orderBy(this.contents, content => parseInt(content.id), [
        'desc'
      ])
      let contents = temp
      if (
        this.$env.ENVIRONMENT_NAME === 'prod' ||
        this.$env.ENVIRONMENT_NAME === 'dev'
      ) {
        contents = filter(temp, content => {
          const createDate = content.detail.description.createDate
          return isAfter(new Date(), new Date(createDate))
        })
      }
      if (!this.homepage) {
        const slug = this.$route.params.slug.toLowerCase()
        contents = filter(contents, content => content.slug !== slug)
      }
      return contents.slice(0, 3)
    }
  },
  methods: {
    isShowRevamp() {
      return this.$features('DWB-XXX-REVAMP-2023-Q4-F')
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// .read-all {
//   color: $crimson;
//   size: 16px;
//   @include mobile {
//     size: 14px;
//   }
// }
.blogs {
  padding: 2rem 0;
  max-width: 1068px;
  margin: 0 auto;

  @media screen and (max-width: 1084px) {
    margin: 0 16px;
  }
}
.blog-section-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.55rem;

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}
.revamp-blogs.is-homepage {
  // #topic-footer-content {
  //   font-size: 32px;
  // }
  // .read-all {
  //   color: #594c4b;
  //   width: 101px;
  //   height: 36px;
  //   border-radius: 32px;
  //   border: 1px solid #e6e6e6;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  ::v-deep {
    .contents-body-item {
      border-radius: 32px;
      border: 1px solid #e6e6e6;
    }
    .content {
      text-align: center;
      margin: 24px;
    }
    .text-wrapper p {
      font-weight: 700;
    }
    .thumbnail {
      border-radius: unset;
      margin-bottom: 0;
    }
    .blogs {
      padding: 0;
      margin-bottom: 40px;
      max-width: 1200px;
    }
    .revamp-breadcrumbs {
      background: transparent;
    }
    .text-wrapper {
      * {
        font-size: 16px;
      }
      span {
        display: inline-block;
        font-size: 16px;
      }
    }
    @media (max-width: 991px) {
      .content {
        margin-top: 8px;
      }
      .thumbnail {
        min-height: 124px;
      }
      .text-wrapper > p {
        font-size: 16px;
        height: 46px;
      }
      .read-more {
        font-size: 14px;
      }
    }
  }
  // @media (max-width: 991px) {
  //   // .read-all {
  //   //   font-size: 14px;
  //   // }
  //   #topic-footer-content {
  //     font-size: 20px;
  //   }
  // }
}
</style>

<template>
  <FaqSectionLayout title="คำถามที่พบบ่อย">
    <Accordion class="faq-accordion" :items="items" />
    <nuxt-link to="/frequently-asked-questions">
      <Button>อ่านคำถามเพิ่มเติม ได้ที่นี่</Button>
    </nuxt-link>
  </FaqSectionLayout>
</template>

<script>
import Vue from 'vue'

import Accordion from '~/components/commons/organisms/accordion/accordion-faq.vue'
import { TextBody } from '~/components/commons/bases/typography'
import Button from '~/components/commons/bases/button/button.vue'
import { List, OrderList, UnorderList } from '~/components/commons/bases/lists'
import FaqSectionLayout from '~/components/layouts/faq-section-layout.vue'

export default Vue.extend({
  components: {
    Accordion,
    Button,
    FaqSectionLayout
  },
  data() {
    return {
      items: [
        {
          title: 'รีไฟแนนซ์รถต่างจากการเช่าซื้อรถ อย่างไร',
          titleTag: 'h2',
          content: (
            <div>
              <TextBody class="with-bottom-rhythm">
                การรีไฟแนนซ์รถยนต์ คือ การที่นำรถของตนเองมาขอสินเชื่อ
                ซึ่งการรีไฟแนนซ์ที่ว่านี้ เราเลือกได้ว่า ต้องการดอกเบี้ยคงที่
                หรือต้องการดอกเบี้ยแบบลดต้นลดดอก
              </TextBody>
              <TextBody class="with-bottom-rhythm">
                ส่วนการเช่าซื้อรถยนต์
                เป็นสินเชื่อสำหรับผู้ที่ประสงค์จะซื้อรถยนต์
                โดยต้องการผ่อนชำระเป็นงวดๆ เมื่อชำระครบ
                กรรมสิทธิ์ในรถจะเป็นของลูกค้าทันที ได้ทั้งรถยนต์ใหม่ป้ายแดง
                หรือรถใช้แล้ว
              </TextBody>
              <TextBody>
                การจะเลือก refinance ที่ไหนดีนั้น
                อยากให้ดูสิ่งที่เราต้องการที่สุดเป็นหลัก เช่น
                เราต้องการเงินด่วนจริงๆ
                ที่ไหนอนุมัติสินเชื่อนำรถมาแลกเงินได้ไวสุดและได้ดอกเบี้ยในระดับที่เรารับได้(ซึ่งดอกเบี้ยอาจจะไม่ถูกที่สุด)
                ที่
                สำคัญมีการเปรียบเทียบดอกเบี้ยเงินกู้ให้เห็นทั้งแบบดอกเบี้ยคงที่และดอกเบี้ยลดต้นลดดอกก็จะยิ่งเพิ่มความน่าเชื่อถือได้ดีที่สุด
              </TextBody>
            </div>
          )
        },
        {
          title: 'อยากเอารถที่ยังผ่อนไม่หมดกับไฟแนนซ์ มารีไฟแนนซ์ทำได้ไหม',
          titleTag: 'h2',
          content: (
            <div>
              <TextBody>
                ทำได้อยู่แล้ว เราอยู่ในยุคที่ผู้บริโภคเลือกได้ :)
                โดยขั้นตอนจะเริ่มจาก
              </TextBody>
              <UnorderList>
                <TextBody as={List}>
                  ปิดสัญญาเก่าจากไฟแนนซ์ที่เราผ่อนอยู่ให้เรียบร้อยก่อน
                  ซึ่งจะมีค่าปิดสัญญาก่อนกำหนดด้วย
                  (ถามจากไฟแนนซ์ที่เดิมก่อนว่าเป็นเงินเท่าไหร่
                  คุ้มไหมที่จะไปรีไฟแนนซ์กับที่ใหม่)
                </TextBody>
                <TextBody as={List}>
                  ติดต่อรีไฟแนนซ์กับที่ใหม่ ที่เราต้องรู้ว่า
                </TextBody>
                <OrderList>
                  <TextBody as={List}>
                    ค่างวดในการผ่อนถูกลงหรือใกล้เคียงกับยอดเดิมที่จ่ายอยู่
                    แต่เราได้รับยอดสินเชื่อที่มากขึ้นกว่าเดิม
                  </TextBody>
                  <TextBody as={List}>
                    เปรียบเทียบค่างวดใหม่ทั้งแบบดอกเบี้ยคงที่
                    และแบบดอกเบี้ยลดต้นลดดอกจนจบอายุสัญญาใหม่ว่า
                    เราได้รับส่วนต่างของราคาประเมิน ณ
                    วันที่เราขอสินเชื่อที่ไหนมากที่สุดเพราะนั่นคือ Cash Out
                    หรือเงินส่วนต่างที่เราได้รับเพิ่มจากการรีไฟแนนซ์
                  </TextBody>
                  <TextBody as={List}>
                    โปรโมชันที่มีตรงกับความต้องการของเราจริงๆ เช่น
                    ผ่อนได้นานขึ้นเพื่อแบ่งเบาภาระในการผ่อนแต่ละงวดลง
                    หรือได้รับอัตราดอกเบี้ยพิเศษเมื่อเทียบกับที่อื่นๆ
                    เพราะถ้าเราไม่ได้อะไรที่ดีขึ้นกว่าเดิม
                    ก็ไม่คุ้มที่ต้องไปรีไฟแนนซ์เพื่อจ่ายค่าปิดสัญญาไฟแนนซ์ก่อนกำหนดเลย
                  </TextBody>
                </OrderList>
              </UnorderList>
            </div>
          )
        },
        {
          title: 'Refinance รถปิดหนี้บัตรเครดิตจะดีไหม',
          titleTag: 'h2',
          content: (
            <div>
              <TextBody>
                ก่อนที่จะตอบคำถามนี้ อยากเอาข้อเท็จจริงมาเล่าให้ฟังก่อนว่า
              </TextBody>
              <UnorderList>
                <TextBody as={List}>
                  อัตราดอกเบี้ยของบัตรเครดิตธนาคารพาณิชย์
                  ตามข้อกำหนดของธนาคารแห่งประเทศไทย (Bank of Thailand)
                  สูงสุดอยู่ที่ร้อยละ 18
                  ต่อปีซึ่งก็เกือบทุกบัตรเครดิตที่คิดดอกเบี้ย ที่อัตราสูงสุด
                </TextBody>
                <TextBody as={List}>
                  อัตราดอกเบี้ยของบัตรกดเงินสด หรือสินเชื่อส่วนบุคคลต่างๆ
                  ตามข้อกำหนดของธนาคารแห่งประเทศไทย (Bank of Thailand)
                  สูงสุดอยู่ที่ร้อยละ 28 ต่อปี
                </TextBody>
                <TextBody as={List}>
                  อัตราดอกเบี้ยแบบคงที่ refinance รถจากคาร์ ฟอร์ แคช
                  เริ่มต้นที่ร้อยละ 3.18 ต่อปี และอัตราดอกเบี้ย refinance
                  รถแบบลดต้นลดดอกจาก Car4Cash เริ่มต้นที่ร้อยละ 12 ต่อปี
                </TextBody>
              </UnorderList>
              <TextBody>
                ซึ่งจากตัวเลขที่เห็น ก็น่าจะทำให้เราคำนวณได้คร่าวๆ
                ในใจว่าตัวเลขแบบไหนที่จะช่วยให้เราลดดอกเบี้ยดีที่สุด
                โดยเฉพาะการรวมหนี้บัตรเครดิตหลายๆ
                ใบแล้วใช้การรีไฟแนนซ์รถเข้ามาช่วยจัดการเคลียร์หนี้
                ดูตัวอย่างจริงกันเลยว่า
              </TextBody>
              <UnorderList>
                <TextBody as={List}>
                  ถ้านำรถมาแลกเงินกับกรุงศรี ออโต้ คิดอัตราดอกเบี้ยเริ่มต้นแค่
                  4% ต่อปี และในกรณีที่คุณมีหนี้คงค้างอยู่ที่ 100,000 บาท -
                  คิดดอกเบี้ยบัตรเครดิตจากธนาพาณิชย์
                  ตามอัตราที่ธนาคารแห่งประเทศไทยกำหนดไว้ 18% จะเทียบเท่ากับ
                  100,000 x 18% = 18,000 บาทต่อปี
                </TextBody>
                <TextBody as={List}>
                  แต่ดอกเบี้ยของการ Refinance รถจะเป็น 100,000 x 4% = 4,000
                  บาทต่อปี ทำให้ประหยัดไปได้ถึง 14,000 บาท เลยทีเดียว Car4Cash
                  ขอแนะนำเพิ่มเติมว่าการรีไฟแนนซ์รถไม่ได้จำกัดแค่การปิดหนี้บัตรเครดิต
                  แต่ยังนำไปใช้ในการปิดสินเชื่อแบบอื่นได้ด้วย
                  ซึ่งแง่มุมที่ควรต้องตรวจสอบก่อนตัดสินใจเพื่อประโยชน์สูงสุดของลูกค้าควรจะดูให้มั่นใจว่า
                  <OrderList>
                    <TextBody as={List}>
                      อัตราดอกเบี้ยเงินกู้ของการรีไฟแนนซ์รถถูกกว่าสินเชื่อเดิม
                      ทำให้ภาระค่างวดต่อเดือนถูกกว่าไหม
                    </TextBody>
                    <TextBody as={List}>
                      ยอดจัดสินเชื่อของรีไฟแนนซ์รถเท่ากับหรือมากกว่าสินเชื่อเดิมหรือไม่
                    </TextBody>
                    <TextBody as={List}>
                      ได้รับส่วนต่างราคาประเมินของรถที่มากขึ้นเมื่อเทียบกับยอดจัดเดิม
                      เพราะรถยนต์มีค่าเสื่อมตามอายุการใช้งานด้วย
                      และเรายังสามารถนำจำนวนเงินส่วนต่างที่ไปได้
                      ไปหมุนเวียนใช้จ่ายเรื่องๆอื่นๆที่สำคัญกว่าได้อีกด้วย
                    </TextBody>
                  </OrderList>
                </TextBody>
              </UnorderList>
            </div>
          )
        },
        {
          title:
            'ผ่อนรถหมดแล้ว อยากเอารถมารีไฟแนนซ์ต้องทำอย่างไร มีค่าใช้จ่ายไหม',
          titleTag: 'h2',
          content: (
            <div>
              <TextBody>
                รถที่ผ่อนหมดแล้วสามารถนำมารีไฟแนนซ์รถได้ไม่มีปัญหา
                ซึ่งขั้นตอนที่เราจะไปรีไฟแนนซ์รถนั้น เริ่มจาก
              </TextBody>
              <OrderList>
                <TextBody as={List}>
                  ค้นหาโปรโมชันของสินเชื่อรถยนต์ หรือรีไฟแนนซ์รถตามที่คุณต้องการ
                  เช่น ดอกเบี้ยต่ำ รู้วงเงินประเมิน ได้รับการอนุมัติสูง
                  หรือผ่อนนาน
                </TextBody>
                <TextBody as={List}>
                  กรอกรายละเอียดรถ เพื่อประเมินวงเงิน
                  และเลือกอัตราดอกเบี้ยที่เหมาะสมที่สุด
                </TextBody>
                <TextBody as={List}>
                  รอเจ้าหน้าที่จาก Car4Cash โทรติดต่อกลับภายใน 1 ชั่วโมง
                  ในวันและเวลาทำการ
                </TextBody>
                <TextBody as={List}>
                  นัดหมายเจ้าหน้าที่ไปรับเอกสารและตรวจสภาพรถ
                </TextBody>
                <TextBody as={List}>
                  ประเมินวงเงินจริงที่จะได้รับการอนุมัติ
                </TextBody>
                <TextBody as={List}>รอรับเงิน</TextBody>
              </OrderList>
              <TextBody>
                ค่าใช้จ่ายและเอกสารที่ต้องใช้ในการรีไฟแนนซ์รถ คือ
              </TextBody>
              <UnorderList>
                <TextBody as={List}>
                  ค่าจดทะเบียนโอนกรรมสิทธิ์รถที่กรมการขนส่งทางบก
                  ตอนทำสัญญาเช่าซื้อ
                </TextBody>
                <TextBody as={List}>
                  ค่าตรวจสภาพรถ (ในกรณีที่เป็นรถเก่า)
                </TextBody>
              </UnorderList>
            </div>
          )
        }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.faq-accordion {
  margin-bottom: 2rem;
}
.with-bottom-rhythm {
  margin-bottom: 1.5rem;
}
</style>

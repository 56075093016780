<template>
  <SectionContainer background-color="grey">
    <div class="faq-section">
      <div class="faq-left-side">
        <div class="faq-image-wrapper">
          <div class="faq-image" />
        </div>
      </div>
      <div class="faq-right-side">
        <TitleMedium as="h2" class="title">{{ title }}</TitleMedium>
        <slot></slot>
      </div>
    </div>
  </SectionContainer>
</template>

<script>
import Vue from 'vue'

import SectionContainer from '~/components/layouts/section-container/section-container.vue'
import { TitleMedium } from '~/components/commons/bases/typography'

export default Vue.extend({
  components: {
    TitleMedium,
    SectionContainer
  },
  props: {
    title: {
      type: String,
      required: true,
      default: null
    }
  }
})
</script>

<style lang="scss" scoped>
.faq-section {
  display: grid;
  grid-gap: 1.75rem;
  padding-top: 3rem;
  grid-template-columns: 1fr;
  min-height: 27.5rem;

  @include mobile {
    padding-top: 0rem;
  }
  @include tablet {
    padding-top: 0rem;
  }

  @include desktop {
    padding-top: 3rem;
    grid-template-columns: 1fr 1fr;
  }
}
.title {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.faq-left-side {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.faq-image-wrapper {
  @include tablet {
    display: none;
  }
  @include desktop {
    display: block;
    width: 392px;
    margin: 0 auto;
    height: 100%;
  }
}
.faq-image {
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-image: url('~assets/images/woman-faq1.png');
  background-repeat: no-repeat;
}
.faq-right-side {
  margin-bottom: 4.5rem;
  text-align: center;
}
</style>

import { render, staticRenderFns } from "./quad-items-layout.vue?vue&type=template&id=d5376046&scoped=true&"
import script from "./quad-items-layout.vue?vue&type=script&lang=ts&"
export * from "./quad-items-layout.vue?vue&type=script&lang=ts&"
import style0 from "./quad-items-layout.vue?vue&type=style&index=0&id=d5376046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5376046",
  null
  
)

export default component.exports














import Vue from 'vue'
import ContentContainer from '~/components/commons/bases/content-container/content-container.vue'
import ProductBox from '~/components/commons/organisms/product-box/product-box.vue'
import TitleMedium from '~/components/commons/bases/typography/title-medium/title-medium.vue'

export default Vue.extend({
  name: 'ProductSection',
  components: { ProductBox, TitleMedium, ContentContainer },
  data(): {
    products: any
  } {
    return {
      products: [
        {
          image: 'images/product_car1.jpg',
          title: 'รถยนต์',
          titleEn: 'Car',
          link: { name: 'product-car', query: { eventscp: 3 } },
          altText: 'สินเชื่อรถยนต์-Car4Cash',
          dataTest: 'index-product-car'
        },
        {
          image: 'images/product_bigbike1.jpg',
          title: 'บิ๊กไบค์',
          titleEn: 'Big Bike',
          link: { name: 'product-big-bikes', query: { eventscp: 7 } },
          altText: 'สินเชื่อบิ๊กไบค์-Car4Cash',
          dataTest: 'index-product-bb'
        },
        {
          image: 'images/product_motor1.jpg',
          title: 'มอเตอร์ไซค์',
          titleEn: 'Motorcycle',
          link: { name: 'product-motorbike', query: { eventscp: 10 } },
          altText: 'สินเชื่อมอเตอร์ไซค์-Car4Cash',
          dataTest: 'index-product-nmc'
        }
      ]
    }
  }
})









import Vue from 'vue'
import { isWithinInterval } from 'date-fns'
import ShellMixin from '~/mixins/shellMixin'
import OldHomePage from '~/components/homepage/old-version.vue'
import RevampVersion from '~/components/homepage/revamp-version.vue'

export default Vue.extend({
  components: {
    OldHomePage,
    RevampVersion
  },
  mixins: [ShellMixin],
  mounted() {
    this.$analytics.homePage.land()

    const onTimeSpacialDay = isWithinInterval(
      new Date(), // Now
      {
        start: new Date('2024-12-01 00:00:00 GMT+07:00'.replace(/-/g, '/')),
        end: new Date('2024-12-07 23:59:59 GMT+07:00'.replace(/-/g, '/'))
      }
    )

    if (this.$features('SPACIAL-DAY-PAGE-F')) {
      //  this.loading.finish()
      if (
        this.$cookies.get('visited-splash-page') === undefined &&
        onTimeSpacialDay
      ) {
        this.$router.push('/splash-page')
      }
    }
  },
  methods: {
    getCookie(cname) {
      const name = cname + '='
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      // console.log(ca)
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    checkCookie(name, sname) {
      let cname = ''
      cname = this.getCookie(name)
      if (cname !== '') {
        // console.log('Welcome again ' + user)
        const ca = cname.split('&')
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i]
          while (c.charAt(0) === ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(sname) === 0) {
            // return c.substring(sname.length, c.length)
            const v = c.substring(sname.length + 1, c.length).split(',')
            const av = []
            v.map(r => {
              const aa = r.split(':')
              if (aa[0] === 'C0001') {
                // console.log(
                //   'คุกกี้ที่มีความจำเป็นต่อการใช้งานเว็บไซต์ = ',
                //   aa[1]
                // )
                av['คุกกี้ที่มีความจำเป็นต่อการใช้งานเว็บไซต์'] = aa[1]
              } else if (aa[0] === 'C0002') {
                // console.log(
                //   'คุกกี้เพื่อเพิ่มประสิทธิภาพในการใช้งานเว็บไซต์ = ',
                //   aa[1]
                // )
                av['คุกกี้เพื่อเพิ่มประสิทธิภาพในการใช้งานเว็บไซต์'] = aa[1]
              } else if (aa[0] === 'C0004') {
                // console.log('คุกกี้เพื่อการวิเคราะห์ข้อมูล = ', aa[1])
                av['คุกกี้เพื่อการวิเคราะห์ข้อมูล'] = aa[1]
              } else {
                console.log('No Data')
              }
            })
            console.table(av)
          }
        }
      }
    }
  },
  head() {
    return {
      title:
        'กรุงศรี คาร์ ฟอร์ แคช สินเชื่อรถ รีไฟแนนซ์รถ อนุมัติเร็ว ดอกเบี้ยต่ำ วงเงินสูง',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.car4cash.com'
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'กรุงศรี คาร์ ฟอร์ แคช บริการสินเชื่อสำหรับคนมีรถ รีไฟแนนซ์รถยนต์ มอเตอร์ไซค์ บิ๊ก ไบค์ สมัครง่าย อนุมัติไว เพิ่มสภาพคล่อง รับรีไฟแนนซ์ รถคันแรก รถมือสอง รถยนต์ที่ยังผ่อนไม่หมด'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            'สินเชื่อรถ, รีไฟแนนซ์รถ, รีไฟแนนซ์รถยนต์, รีไฟแนนซ์รถมอเตอร์ไซค์, รีไฟแนนซ์รถบิ๊ก ไบค์'
        }
      ]
    }
  }
})




















import Vue from 'vue'
import ContentContainer from '~/components/commons/bases/content-container/content-container.vue'
import LendingPurposeContent from '~/components/commons/organisms/lending-purpose-content/lending-purpose-content.vue'
import ShellMixin from '~/mixins/shellMixin'

export default Vue.extend({
  name: 'CarForCashCanHelp',
  components: {
    ContentContainer,
    LendingPurposeContent
  },
  mixins: [ShellMixin],
  data(): { contents: Record<string, string>[] } {
    return {
      contents: [
        {
          title: 'ปิดหนี้บัตรเครดิต',
          titleEn: 'Clear credit card debt.',
          imageAlt: 'ปิดหนี้บัตรเครดิต-Car4Cash',
          image: 'images/รีไฟแนนซ์รถ-ปิดบัตร-new.png',
          // TODO: Refactor this
          dataTest: 'payoff-credit',
          description:
            'อยากรวมหนี้บัตรเครดิตหลายใบมาไว้ที่เดียวเพื่อรับดอกเบี้ยที่ต่ำลง Car4Cash ให้ดอกเบี้ยเริ่มต้นที่ 0.27% ต่อเดือน เลือกระยะผ่อนสบายขึ้นได้ หรือจะทำรีไฟแนนซ์บัตรเครดิตเพื่อรับเงินก้อนปิดหนี้บัตร ให้ที่ปรึกษา Car4Cash ช่วยวางแผนการเงิน ผ่อนหนักให้เป็นเบา ให้ชีวิตสบายขึ้นได้',
          description1:
            'อยากรวมหนี้บัตรเครดิตหลายใบมาไว้ที่เดียวเพื่อรับดอกเบี้ยที่ต่ำลง Car4Cash ให้ดอกเบี้ยเริ่มต้นที่ 0.27% ต่อเดือน เลือกระยะ ...',

          link: '/clear-credit-card-debt'
        },
        {
          title: 'ต่อยอดธุรกิจ',
          titleEn: 'Business expansion.',
          imageAlt: 'หาทุนต่อยอดธุรกิจ-Car4Cash',
          image: 'images/รถแลกเงิน-ปิดบัตร-new1.png',
          dataTest: 'evolve-business',
          description:
            'อยากมีเงินก้อน หาทุนเริ่มต้นขายของออนไลน์ เพิ่มเงินหมุนเวียนในการทำร้านกาแฟ หรือหาเงินเพิ่มทุนทำธุรกิจ SMEs สามารถนำรถมาขอสินเชื่อรถ อนุมัติเร็ว หรือทำรีไฟแนนซ์รถ Car4Cash ได้รับเงินก้อนเร็ว อนุมัติง่าย ได้เต็มวงเงินประเมิน',
          description1:
            'อยากมีเงินก้อน หาทุนเริ่มต้นขายของออนไลน์ เพิ่มเงินหมุนเวียนในการทำร้านกาแฟ หรือหาเงินเพิ่มทุนทำธุรกิจ SMEs สามารถ ...',

          link: '/business-expansion'
        },
        {
          title: 'ตกแต่งบ้าน',
          titleEn: 'Home decoration.',
          imageAlt: 'หาเงินตกแต่งบ้าน-Car4Cash',
          image: 'images/รีไฟแนนซ์รถ-ตกแต่งบ้าน-new.png',
          dataTest: 'decorate-house',
          description:
            'เมื่อครอบครัวใหญ่ขึ้น บ้านของเราก็ต้องเตรียมตกแต่งใหม่ไว้ด้วย การหาสินเชื่อไว้ซื้อเครื่องใช้ไฟฟ้าเครื่องใหม่ ต่อเติมบ้านเพื่อเพิ่มพื้นที่ใช้งาน หรือตกแต่งห้องใหม่ให้เหมาะสมกับการใช้งานมากขึ้น ควรเลือกสินเชื่อที่เราสามารถผ่อนได้สบายที่สุด Car4Cash ช่วยวางแผนให้คุณได้',
          description1:
            'เมื่อครอบครัวใหญ่ขึ้น บ้านของเราก็ต้องเตรียมตกแต่งใหม่ไว้ด้วย การหาสินเชื่อไว้ซื้อเครื่องใช้ไฟฟ้าเครื่องใหม่ ต่อเติมบ้านเพื่อ ...',

          link: '/home-decoration'
        },
        {
          title: 'ต้องการค่าเทอม',
          titleEn: 'Educational payment.',
          imageAlt: 'หาเงินจ่ายค่าเทอม-Car4Cash',
          image: 'images/รถแลกเงิน-ค่าเทอม-new1.jpg',
          dataTest: 'school-fee',
          description:
            'การสร้างอนาคตให้ลูกเป็นเรื่องที่พ่อแม่ยุคใหม่ต้องวางแผนจริงจัง เพราะค่าเทอมตั้งแต่อนุบาลจนจบปริญญาตรีไม่ใช่เรื่องเล็กๆ หากบางช่วงเราหมุนเงินไม่ทันให้ Car4Cash ช่วยดูแลได้ด้วยการรับเงินกู้ด่วนภายใน 1 วันเมื่อผ่านการอนุมัติ',
          description1:
            'การสร้างอนาคตให้ลูกเป็นเรื่องที่พ่อแม่ยุคใหม่ต้องวางแผนจริงจัง เพราะค่าเทอมตั้งแต่อนุบาลจนจบปริญญาตรีไม่ใช่เรื่องเล็กๆ ...',

          link: '/educational-payment'
        }
      ]
    }
  }
})
